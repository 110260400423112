import mock from '../mock'
import { paginateArray, sortCompare } from '../utils';

const data = {
    invoices: [{
            "UASG": 1234,
            "abertura": "15 de junho de 2021 às 09:00h",
            "id": 1,
            "modalidade": "PREGÃO ELETRÔNICO",
            "municipio": "Açailândia",
            "num_licitacao": "030/2021",
            "objeto": "CONTRATANTE: Câmara Municipal de São Raimundo das Man- registro de preços para eventual contratação de pessoa(s) jurídica(s) gabeiras/Ma - CNPJ: 01.715.633/0001-49.",
            "orgao": "adm",
            "tipo_item": "Menor PrePUBLICAÇÃO DE DISPENSA ",
            "valor_estimado": null
        },
        {
            "UASG": 1234,
            "abertura": "25 horas do dia 15/06/2021",
            "id": 2,
            "modalidade": "PreGÃo eLetrÔNico",
            "municipio": "Cumaru do Norte",
            "num_licitacao": "005.2021",
            "objeto": "locação de Veículos automotores, tratores agrícolas e Maquina Pessada para PreFeitUra MUNiciPaL de cUMarU do Norte - Pa atendimento as necessidades da Prefeitura Municipal de irituia - Secretarias eXtrato de reGistro de PreÇos o MUNicÍPio de cUMarU do Norte - Pa.",
            "orgao": "s Solicitantes Nos termos da lei",
            "tipo_item": null,
            "valor_estimado": "59.450,00"
        },
        {
            "UASG": "980369",
            "abertura": null,
            "id": 3,
            "modalidade": "PrEGÃo ElEtrÔNico",
            "municipio": "Placas",
            "num_licitacao": "0030/2021",
            "objeto": "atENdEr aS NEcESSidadES da PrEfEitUra, SEcrEtariaS E fUNdoS MUNici- .",
            "orgao": "adm",
            "tipo_item": null,
            "valor_estimado": null
        },
        {
            "UASG": 1234,
            "abertura": null,
            "id": 4,
            "modalidade": "PreGÃo eLetrÔNico",
            "municipio": "Soure",
            "num_licitacao": "9/2021-020",
            "objeto": "contratação de empresa especializada na prestação de serviço de desin- doS a dESENVolViMENto daS atiVidadES da SEcrEtaria MUNiciPal fecção ágil que comprovadamente abranja todas as superfícies do local e o ar, dE iNfraEStrUtUra, de acordo com o que determina a legislação vigente, a realizar-se no Portal de compras Públicas, no sítio www.",
            "orgao": "adm",
            "tipo_item": "menor preço por itEM",
            "valor_estimado": "15.745,50"
        },
        {
            "UASG": 1234,
            "abertura": "17/06/2021, às 10:00hs (dez)",
            "id": 5,
            "modalidade": "PREGÃO ELETRÔNICO",
            "municipio": "Aldeias Altas",
            "num_licitacao": "023/2021",
            "objeto": "Registro de Preços, vi- Bezerra - MA, 01 de Junho de 2021.",
            "orgao": " da Administração Públi",
            "tipo_item": "menor preço",
            "valor_estimado": "187.326,56"
        },
        {
            "UASG": 1234,
            "abertura": "31/05/2021, às 08:00h (oito horas,",
            "id": 6,
            "modalidade": "PRESENCIAL",
            "municipio": "Cantá",
            "num_licitacao": "024/2021",
            "objeto": null,
            "orgao": "adm",
            "tipo_item": null,
            "valor_estimado": null
        },
        {
            "UASG": 1234,
            "abertura": null,
            "id": 7,
            "modalidade": "ELETRÔNICO",
            "municipio": "Boa Vista",
            "num_licitacao": null,
            "objeto": null,
            "orgao": "adm",
            "tipo_item": null,
            "valor_estimado": null
        },
        {
            "UASG": 1234,
            "abertura": null,
            "id": 8,
            "modalidade": ".PE.",
            "municipio": "null",
            "num_licitacao": "0023",
            "objeto": null,
            "orgao": "adm",
            "tipo_item": null,
            "valor_estimado": null
        },
        {
            "UASG": 1234,
            "abertura": "00:01 horas do dia 08/06/2021",
            "id": 9,
            "modalidade": "Pregão Eletrônico",
            "municipio": "Águas de Chapecó",
            "num_licitacao": "0294/2020",
            "objeto": "resultado da Licitação nº 0110/2021 na modalidade: Pregão Contratação de empresa especializada para execução de SERVIÇOS \"Eletrônico\", cujo objeto: Registro de Preços para futura e eventual SECRETARIA DE ESTADO DO DESENVOLVIMENTO SOCIAL - SDS DE MANUTENÇÃO CORRETIVA E PREVENTIVA E INSTALAÇÃO aquisição de água mineral em bombona de 20 litros.",
            "orgao": "adm",
            "tipo_item": null,
            "valor_estimado": null
        },
        {
            "UASG": "974200",
            "abertura": "28/06/2021, às 10h",
            "id": 10,
            "modalidade": "REGISTRO DE PREÇO",
            "municipio": "Brasília",
            "num_licitacao": "61/2020",
            "objeto": "Contratação de serviços de vigilância humana Aditivo.",
            "orgao": "adm",
            "tipo_item": null,
            "valor_estimado": "5.010.050,00"
        },
        {
            "UASG": 1234,
            "abertura": "17 de Junho de 2021, ás 08:00 horas (Horário de Mato Grosso,",
            "id": 11,
            "modalidade": "PREGÃO PRESENCIAL",
            "municipio": "Colíder",
            "num_licitacao": "027/2021",
            "objeto": "Prestação de serviços de locação de veículo tipo A Prefeitura Municipal de Itaúba - MT, através de seu Pregoeiro, torna VAN, para transportes de pacientes da Secretaria Municipal de Saúde e público, para conhecimento dos interessados, que fará realizar licitação na Saneamento Básico do Município de Colíder-MT.",
            "orgao": "adm",
            "tipo_item": null,
            "valor_estimado": "34.080,00"
        },
        {
            "UASG": 1234,
            "abertura": null,
            "id": 12,
            "modalidade": "Dispensa de Licitação",
            "municipio": "Adamantina",
            "num_licitacao": "20.5.00102.23.6",
            "objeto": null,
            "orgao": "adm",
            "tipo_item": null,
            "valor_estimado": null
        },
        {
            "UASG": 1234,
            "abertura": null,
            "id": 13,
            "modalidade": "Edital",
            "municipio": "Araraquara",
            "num_licitacao": "352/0024/21",
            "objeto": null,
            "orgao": "adm",
            "tipo_item": null,
            "valor_estimado": null
        },
        {
            "UASG": 1234,
            "abertura": "14/06/2021 às 14h00min.",
            "id": 14,
            "modalidade": "PREGÃO ELETRONICO",
            "municipio": "Castro",
            "num_licitacao": "44/2021",
            "objeto": "presente licitação tem por objeto \"Contratação de Empresa especializada para fornecimento e locação de cilindros OSMARIO BONFIM DE CASTRO de oxigênio medicinal para atendimento de urgência e emergência Secretário Municipal de Saúde a pacientes atendidos pala AssistênciaSocial, Unidades de Saúde do Município, SAMU e demais veículos (Ambulâncias, para a NENEU JOSE ARTIGAS Secretaria Municipal de Saúde\" Prefeito Municipal VALOR MÁXIMO: O preço máximo global da presente licitação Publicado por: será de: R$ 453.999,00 (Quatrocentos e cinquenta e três mil Adriana Maria Bonfim dos Santos novecentos e noventa e nove reais) Código Identificador:F30F516B",
            "orgao": "adm",
            "tipo_item": null,
            "valor_estimado": null
        },
        {
            "UASG": 1234,
            "abertura": "21/6/2021 às 8h15m",
            "id": 15,
            "modalidade": "PREGÃO ELETRÔNICO",
            "municipio": "Aracaju",
            "num_licitacao": "064/2021",
            "objeto": "Registro de preços, pelo menor preço por lote, no prazo de 12(doze) meses, para eventual e futura aquisição de fragmentadoras de papel, para atender a demandas internas das diversas Unidades da Companhia de Saneamento de Sergipe - DESO.",
            "orgao": " superior deliberativo",
            "tipo_item": null,
            "valor_estimado": "498.718,13"
        },
        {
            "UASG": 1234,
            "abertura": null,
            "id": 16,
            "modalidade": "PREGÃO ELETRÔNICO",
            "municipio": "Aracaju",
            "num_licitacao": "008/2021",
            "objeto": null,
            "orgao": "adm",
            "tipo_item": null,
            "valor_estimado": null
        },
        {
            "UASG": 1234,
            "abertura": "14/06/2021 às 08h",
            "id": 17,
            "modalidade": "Pregão Eletrônico",
            "municipio": "Itu",
            "num_licitacao": "03/21",
            "objeto": "Prestação de serviços A VISO DE LICITAÇÃO 1 - 375 - Kg - Alho Processado - 5,18 - 1.942,50 PREFEITURA MUNICIPAL DE SOROCABA de locação de veículos mensal e sob demanda.",
            "orgao": "adm",
            "tipo_item": null,
            "valor_estimado": null
        },
        {
            "UASG": 1234,
            "abertura": null,
            "id": 18,
            "modalidade": "pregão presencial",
            "municipio": "Araraquara",
            "num_licitacao": "033-2021",
            "objeto": "resumido: Contratação de empresa para a execução de obra de construção CONTRATO Nº 022/2018 - 6º TERMO DE PRORROGAÇÃO daae n.",
            "orgao": "adm",
            "tipo_item": null,
            "valor_estimado": "447.276,36"
        },
        {
            "UASG": 1234,
            "abertura": null,
            "id": 19,
            "modalidade": "Pregão Eletrônico",
            "municipio": "Barbosa",
            "num_licitacao": "106",
            "objeto": null,
            "orgao": "adm",
            "tipo_item": null,
            "valor_estimado": null
        },
        {
            "UASG": 1234,
            "abertura": null,
            "id": 20,
            "modalidade": "CONVITE",
            "municipio": "Assis",
            "num_licitacao": "007/2021",
            "objeto": null,
            "orgao": "adm",
            "tipo_item": null,
            "valor_estimado": null
        }
    ]
}

// ------------------------------------------------
// GET: Return Invoice List
// ------------------------------------------------
mock.onGet('/apps/invoice/invoices').reply(config => {
    // eslint-disable-next-line object-curly-newline
    const { q = '', perPage = 10, page = 1, status = null } = config
    /* eslint-enable */
    const queryLowered = q.toLowerCase()
    const filteredData = data.invoices
        .filter(
            invoice =>
            /* eslint-disable operator-linebreak, implicit-arrow-linebreak */
            (invoice.orgao.toLowerCase().includes(queryLowered) ||
                invoice.municipio.toLowerCase().includes(queryLowered))
        )
        .sort(sortCompare('id'))
        .reverse()
        /* eslint-enable  */

    return [
        200,
        {
            allData: data.invoices,
            invoices: paginateArray(filteredData, perPage, page),
            total: filteredData.length
        }
    ]
})

// ------------------------------------------------
// GET: Return Single Invoice
// ------------------------------------------------
mock.onGet(/\/api\/invoice\/invoices\/\d+/).reply(config => {
    // // Get event id from URL
    const invoiceId = Number(config.url.substring(config.url.lastIndexOf('/') + 1))

    const invoiceIndex = data.invoices.findIndex(e => e.id === invoiceId)
    const responseData = {
        invoice: data.invoices[invoiceIndex],
        paymentDetails: {
            totalDue: '$12,110.55',
            bankName: 'American Bank',
            country: 'United States',
            iban: 'ETD95476213874685',
            swiftCode: 'BR91905'
        }
    }
    return [200, responseData]
})

// ------------------------------------------------
// DELETE: Deletes Invoice
// ------------------------------------------------
mock.onDelete('/apps/invoice/delete').reply(config => {
    // Get invoice id from URL
    let invoiceId = config.id

    // Convert Id to number
    invoiceId = Number(invoiceId)

    const invoiceIndex = data.invoices.findIndex(t => t.id === invoiceId)
    data.invoices.splice(invoiceIndex, 1)

    return [200]
})

// ------------------------------------------------
// GET: Return Clients
// ------------------------------------------------
mock.onGet('/api/invoice/clients').reply(() => {
    const clients = data.invoices.map(invoice => invoice.id)
    return [200, clients.slice(0, 5)]
})