// ** Redux Imports
import { combineReducers } from 'redux'

// ** Reducers Imports
import auth from './auth'
import navbar from './navbar'
import layout from './layout';
import todo from '@src/views/apps/todo/store/reducer';
import users from '@src/views/apps/user/store/reducer';
import email from '@src/views/apps/email/store/reducer';
import ecommerce from '@src/views/apps/ecommerce/store/reducer';
import invoice from '@src/views/apps/invoice/store/reducer';
import calendar from '@src/views/apps/calendar/store/reducer';
import licitacoes from '@src/views/apps/licitacoes/store/reducer';
import projetosDeLei from '@src/views/apps/projetos-de-lei/store/reducer';
// import dns from '@src/views/apps/dns/store/reducer';
import dataTables from '@src/views/tables/data-tables/store/reducer';

const rootReducer = combineReducers({
  auth,
  todo,
  email,
  ecommerce,
  users,
  navbar,
  layout,
  invoice,
  calendar,
  dataTables,
  licitacoes,
  projetosDeLei,
});

export default rootReducer
