/*eslint-disable */
import mock from '../mock'

const date = new Date()
const nextDay = new Date(new Date().getTime() + 24 * 60 * 60 * 1000)

// prettier-ignore
const nextMonth = date.getMonth() === 11 ? new Date(date.getFullYear() + 1, 0, 1) : new Date(date.getFullYear(), date.getMonth() + 1, 1)
// prettier-ignore
const prevMonth = date.getMonth() === 11 ? new Date(date.getFullYear() - 1, 0, 1) : new Date(date.getFullYear(), date.getMonth() - 1, 1)

const data = {
  events: [
    {
      id: 1,
      url: '',
      title: 'Licitação 7008/2020',
     start: new Date(date.getFullYear(), date.getMonth() + 1, -20),
      end: new Date(date.getFullYear(), date.getMonth() + 1, -19),
      allDay: true,
      extendedProps: {
        calendar: 'Licitações Abertas'
      }
    },
    {
      id: 2,
      url: '',
      title: 'Licitação 5001/2021',
      start: new Date(date.getFullYear(), date.getMonth() + 1, -5),
      end: new Date(date.getFullYear(), date.getMonth() + 1, -4),
      allDay: true,
      extendedProps: {
        calendar: 'Licitações Fechadas'
      }
    },
    {
      id: 3,
      url: '',
      title: 'Licitação 3002/2021',
      allDay: true,
      start: new Date(date.getFullYear(), date.getMonth() + 1, -11),
      end: new Date(date.getFullYear(), date.getMonth() + 1, -10),
      allDay: true,
      extendedProps: {
        calendar: 'Licitações em Andamento'
      }
    },
    {
      id: 4,
      url: '',
      title: "PL 3972/2000 adicionado",
      start: new Date(date.getFullYear(), date.getMonth() + 1, -2),
      end: new Date(date.getFullYear(), date.getMonth() + 1, -1),
      allDay: true,
      extendedProps: {
        calendar: 'Projeto de lei'
      }
    },
    {
      id: 5,
      url: '',
      title: 'Dart Game?',
      start: new Date(date.getFullYear(), date.getMonth() + 1, -7),
      end: new Date(date.getFullYear(), date.getMonth() + 1, -6),
      allDay: true,
      extendedProps: {
        calendar: 'DNS'
      }
    },
    {
      id: 6,
      url: '',
      title: 'PL 5256/2016 adicionado',
      start: new Date(date.getFullYear(), date.getMonth() + 1, -9),
      end: new Date(date.getFullYear(), date.getMonth() + 1, -8),
      allDay: true,
      extendedProps: {
        calendar: 'Projeto de lei'
      }
    },
    {
      id: 7,
      url: '',
      title: 'Licitação 8804/2021',
      start: new Date(date.getFullYear(), date.getMonth() + 1, -25),
      end: new Date(date.getFullYear(), date.getMonth() + 1, -24),
      allDay: true,
      extendedProps: {
        calendar: 'Licitações Fechadas'
      }
    },
    {
      id: 8,
      url: '',
      title: 'Product Review',
      start: new Date(date.getFullYear(), date.getMonth() + 1, -13),
      end: new Date(date.getFullYear(), date.getMonth() + 1, -12),
      allDay: true,
      extendedProps: {
        calendar: 'DNS'
      }
    },
    {
      id: 9,
      url: '',
      title: 'PL 7061/2017 adicionado',
      start: new Date(date.getFullYear(), date.getMonth() + 1, -15),
      end: new Date(date.getFullYear(), date.getMonth() + 1, -14),
      allDay: true,
      extendedProps: {
        calendar: 'Projeto de lei'
      }
    },
    {
      id: 10,
      url: '',
      title: 'Licitação 4056/2021',
      start: new Date(date.getFullYear(), date.getMonth() + 1, -15),
      end: new Date(date.getFullYear(), date.getMonth() + 1, -14),
      allDay: true,
      extendedProps: {
        calendar: 'Licitações em Andamento'
      }
    }
  ]
}

// ------------------------------------------------
// GET: Return calendar events
// ------------------------------------------------
mock.onGet('/apps/calendar/events').reply(config => {
  // Get requested calendars as Array
  const calendars = config.calendars

  return [200, data.events.filter(event => calendars.includes(event.extendedProps.calendar))]
})

// ------------------------------------------------
// POST: Add new event
// ------------------------------------------------
mock.onPost('/apps/calendar/add-event').reply(config => {
  // Get event from post data
  const { event } = JSON.parse(config.data)

  const { length } = data.events
  let lastIndex = 0
  if (length) {
    lastIndex = data.events[length - 1].id
  }
  event.id = lastIndex + 1

  data.events.push(event)

  return [201, { event }]
})

// ------------------------------------------------
// POST: Update Event
// ------------------------------------------------
mock.onPost('/apps/calendar/update-event').reply(config => {
  const { event: eventData } = JSON.parse(config.data)

  // Convert Id to number
  eventData.id = Number(eventData.id)

  const event = data.events.find(ev => ev.id === Number(eventData.id))
  Object.assign(event, eventData)

  return [200, { event }]
})

// ------------------------------------------------
// DELETE: Remove Event
// ------------------------------------------------
mock.onDelete('/apps/calendar/remove-event').reply(config => {
  // Get event id from URL
  let { id } = config

  // Convert Id to number
  const eventId = Number(id)

  const eventIndex = data.events.findIndex(ev => ev.id === eventId)
  data.events.splice(eventIndex, 1)
  return [200]
})
