const initialState = {
  projetosData: [],
  projetoData: null,
  params: {},
};

const projetosReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_PROJETOS':
      return {
        ...state,
        projetosData: action.projetosData,
        params: action.params,
      };
    case 'GET_PROJETO':
      return {
        ...state,
        projetoData: action.projetoData,
      };
    default:
      return { ...state };
  }
};

export default projetosReducer;
