const initialState = {
  licitacoesData: [],
  lotesData: [],
  licitacoesCountData: [],
  openLicitacoesData: [],
  params: {},
};

const licitacoesReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_LICITACOES':
      return {
        ...state,
        licitacoesData: action.licitacoesData,
        params: action.params,
      };
    case 'GET_LOTES':
      return {
        ...state,
        lotesData: action.lotesData,
        params: action.params,
      };
    case 'GET_LICITACOES_COUNT':
      return {
        ...state,
        licitacoesCountData: action.licitacoesCountData,
        params: action.params,
      };
    case 'GET_OPEN_LICITACOES':
      return {
        ...state,
        openLicitacoesData: action.openLicitacoesData,
        params: action.params,
      };
    default:
      return { ...state };
  }
};

export default licitacoesReducer;
